import React from "react";

const About = () => {
  return (
    <section className="bg-secondery text-white px-5 py-20" id="about">
      <div className="container mx-auto items-center justify-center md:justify-between">
        <div className="about-info text-center">
          <h2 className="text-4xl font-bold mb-5 mx-auto border-b-[5px] w-[180px] border-indigo-600 pb-2">
            About Me
          </h2>
          <div className="md:w-[60%] mx-auto">
            <p className="pb-5">
              I have experience in Project Management, ranging from Sales and
              Marketing, Education and IT industries. I have also a fond
              interest in solving problem through the use of technologies and
              programming.
            </p>
            <p className="pb-5">
              I am currently pursuing a Master of IT at the University of
              Melbourne for a more in-depth and formal exploration into the
              topics of Cyber Security, AI and Machine Learning.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
