import React from "react";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";

const Contact = () => {
  return (
    <section className="bg-primary px-5 py-20" id="contact">
      <div className="text-center md:w-[60%] mx-auto text-white flex flex-col items-center">
        <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[200px] border-indigo-600 pb-2">
          Contact Me
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <a href="https://www.linkedin.com/in/jeffrey-ck-lau/">
            <AiFillLinkedin size={40} />
          </a>
          <a href="https://github.com/jeffreylauck">
            <AiFillGithub size={40} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
