import React from "react";

const Footer = () => {
  return (
    <div className="py-4 text-center bg-primary text-white text-xs text-slate-500">
      &copy; 2024 Jeffrey Lau All right reserved
    </div>
  );
};

export default Footer;
