import React from "react";
import profilepic from "../asset/profile-pic.jpeg";
const Hero = () => {
  return (
    <section className="bg-primary px-5 text-white py-20">
      <div className="container mx-auto md:w-[60%] items-center justify-center md:justify-between">
        <div className="hero-info pb-5 md:pb-0 flex  flex-col md:flex-row-reverse items-center justify-evenly">
          <img
            className="rounded-full w-48 h-48 m-3 border-4"
            src={profilepic}
            alt="Profile"
          />
          <h1 className="text-4xl md:text-5xl">
            Hi, <br />I am <span className="text-accent">j</span>effrey <br />
            Full Stack Developer & <br />
            Cyber Analyst
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
